html {
	color: #EEE;
	font-family: nimbus-sans, sans-serif;
	font-weight: 300;
	font-style: normal;
	font-size: 2em;
}

body {
	padding: 0;
  margin: 0;
  background-color: #000;
}

h1, h2, h3, h4, h5, h6, .monotalic {
	font-family: monotalic-narrow, sans-serif;
	font-style: normal;
	font-weight: 500;
	line-height: 1.25em;
}

div#root {
	position: relative;
	max-width: 100%;
	overflow: hidden;
}

p {
	line-height: 135%;
}

.description {
	font-size: .55em;
}

@media all and (max-width: 1024px) {
	html {
		font-size: 1.6em;
	}

}

@media all and (max-width: 800px) {
	html {
		font-size: 1.3em;
	}

}